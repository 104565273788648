import { User } from "@/types/user";
import utils from "@/utils/constants";

export const getDisplayName = (user?: User, withTitle?: boolean) => {
  if (!user) {
    return "N/A";
  }

  return (
    (withTitle && user.title) ||
    user.stageName ||
    (user.firstName || user.lastName
      ? `${user.firstName || ""} ${user.lastName || ""}`
      : user.email)
  );
};

export const getVisibleLocation = (pro: User) => {
  const country = utils.countries.find(
    (country) => country.code === pro.country,
  );
  return pro.city
    ? [pro.city, country?.value].filter(Boolean).join(", ")
    : country?.value;
};
